import React, { useState } from "react";
import { Stack, TextField, ComboBox, IComboBoxOption, IComboBoxStyles, IComboBox } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";

interface Props {
  onSend: (question: string) => void
  disabled: boolean
  disabledStyle: boolean
  placeholder?: string
  clearOnSend?: boolean
}

export const QuestionInput = ({ onSend, disabled,disabledStyle, placeholder, clearOnSend }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const maxLength = 200;
    //用户点击发送按钮时，发送问题
    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        // onsend添加一个参数selectedOption
        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };
    //用户按下回车键时，发送问题
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };
    //用户打字时，更改 question 的值
    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
      <Stack
        horizontal
        className={`${styles.questionInputContainer} ${
          disabledStyle ? styles.disabledContainer : ''
        }`}>
        <TextField
          className={styles.questionInputTextArea}
          placeholder={placeholder}
          multiline
          rows={6}
          resizable={false}
          borderless
          maxLength={maxLength}
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
        />

        <div
          className={styles.questionInputSendButtonContainer}
          role="button"
          tabIndex={0}
          aria-label="Ask question button"
          onClick={sendQuestion}
          onKeyDown={(e) =>
            e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null
          }>
          <div style={{ marginRight: '10px' }}>
            <span>
              {question.length}/{maxLength}
            </span>
          </div>
          {sendQuestionDisabled ? (
            <SendRegular className={styles.questionInputSendButtonDisabled} />
          ) : (
            <img src={Send} className={styles.questionInputSendButton} />
          )}
        </div>
        <div className={styles.questionInputBottomBorder} />
      </Stack>
    )
};

import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import CBS_LOGO from "../../assets/cbs_logo.png";
import { Stack } from "@fluentui/react";

const Layout = () => {

    
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Stack horizontal verticalAlign="center">
                        <img
                            src={CBS_LOGO}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>CBS 检测 GPT</h1>
                        </Link>
                    </Stack>
                </div>
            </header>
            <Outlet />
  
        </div>
    );
};

export default Layout;

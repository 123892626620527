import { useEffect, useMemo, useState } from "react";
import {  Stack , IIconProps, FontSizes} from "@fluentui/react";
import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { Spinner,SpinnerSize } from '@fluentui/react/lib/Spinner';

import { ActionButton } from '@fluentui/react/lib/Button';

interface Props {
  answer: AskResponse
  isLoading: boolean
  index: number
  dataClass: string
  onOptionClick:(text:string) => void
  onCitationClicked: (citedDocument: Citation) => void
}
const options = {
  key: 'DelegateHelp',
  items: ['咨询鉴定相关事项','业务范围咨询','直接下单'],
}

const humanHelp: IIconProps = { iconName: 'ArrangeByFrom' };
const refreshIcon: IIconProps = { iconName: 'Refresh' };
const moreVerticalIcon: IIconProps = { iconName: 'MoreVerticalIcon' };

export const Answer = ({
    answer,
    isLoading,
    index,
    dataClass,
    onOptionClick,
    onCitationClicked
}: Props) => {
  const handleClick = (option: any) => {
    onOptionClick(option)
  }
    const assistantIndex = (index + 1) / 2
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    return (
      <>
          <Stack className={styles.answerContainer} tabIndex={0}>
            {/* 具体的回答消息内容 */}
            <Stack.Item grow>
              <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                children={parsedAnswer.markdownFormatText}
                className={styles.answerText}
              />
              {!isLoading && options.key == dataClass && assistantIndex == 1 ? (
                <>
                  {options.items.map((item, index) => (
                    <span
                      key={index}
                      className={styles.subOption}
                      onClick={() =>
                        handleClick({ key: options.key, value: item })
                      }>
                      【{item}】
                    </span>
                  ))}
                </>
              ) : null}
              {isLoading && (
                <>
                  <Spinner size={SpinnerSize.medium} />
                  <p></p>
                </>
              )}
              {/*godgy改*/}
              {answer.answer.includes('条例') && (
                <a
                  href="http://www.npc.gov.cn/npc/c30834/202006/75ba6483b8344591abd07917e1d25cc8.shtml"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span style={{ fontSize: '12px' }}>条例具体内容点击查看</span>
                </a>
              )}
              <div style={{ margin: '1em 0' }}></div>
              {/*godgy改*/}
            </Stack.Item>
            <Stack horizontal className={styles.answerFooter}>
              {!isLoading && !answer.isSelect ? (
                <span className={styles.answerDisclaimer2}>
                  {answer.num+1}共5
                </span>
              ) : null}
              <Stack.Item className={styles.answerDisclaimerContainer}>
                <span className={styles.answerDisclaimer}>
                  答案由 CBS AI 大模型生成
                </span>
              </Stack.Item>
            </Stack>
            <ActionButton iconProps={humanHelp} allowDisabledFocus
            href="mailto:test1@cbs.com.cn;test2@cbs.com.cn?subject=问题咨询&body=这里输入问题">
                咨询人工
            </ActionButton>
          </Stack>
          {/* <Stack>
          <ActionButton iconProps={refreshIcon} allowDisabledFocus
           className={styles.iconSite}/>
          <ActionButton iconProps={moreVerticalIcon} allowDisabledFocus/>
          </Stack> */}
      </>
    )
};
import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";


type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
};
//解析消息
export function parseAnswer(answer: AskResponse): ParsedAnswer {
    let answerText = answer.answer;

    //存储引用的文件对象
    let filteredCitations = [] as Citation[];
   

    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}

import React from 'react'
import { SendRegular } from '@fluentui/react-icons'
import styles from './ChatOptions.module.css'

interface ChatOptionsProps {
  onOptionClick: (option: object) => void
}
export const ChatOptions = ({ onOptionClick }: ChatOptionsProps) => {
  const options = [
    { key: 'DefectDetection', text: '既有房屋的历史缺陷查询' },
    { key: 'EngineeringIssues', text: '工程问题咨询' },
    { key: 'LegalAssistance', text: '司法援助' },
    { key: 'DelegateHelp', text: '委托咨询及下单' }
  ]

  const handleLiClick = (option: object) => {
    onOptionClick(option);
  }
  return (
    <div className={styles.chatOptionsBox}>
      <ul className = {styles.chatOptionsUl}>
        {options.map((option, index) => (
          <li
            key={index}
            className={[styles.chatOptionsLi,styles.borderBottom].join(' ')}
            onClick={() => handleLiClick(option)}>
            <span>{option.text}</span>
            <SendRegular className={styles.questionInputSendButtonDisabled} />
          </li>
        ))}
      </ul>
    </div>
  )
}
const generateUUID = ():string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
    );}


// 生成一个cookie并写入
export const setCookie = ():string => {
    const uniqueId = generateUUID();
    document.cookie = `cbsgptuser_id=${uniqueId}; path=/`;
    return uniqueId;
}

// 查找并获取cookie值
export const getCookie = (cookieName: string): string | null => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
    }
    }
    return null;
}

//获取用户信息
export const getUserInfoList = ():string => {
    const userCookie = getCookie('cbsgptuser_id');
    if (userCookie) {

        return userCookie;
   
    } else {
    // 如果不存在cookie，则生成一个新的cookie并写入
       return setCookie();

    }

   
}
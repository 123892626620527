import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import { DismissRegular, ErrorCircleRegular, SquareRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./Chat.module.css";
import bk from "../../assets/bk.jpg";
import { ChatMessage, ChatResponse, Citation, conversationApi, ConversationRequest } from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ChatOptions } from '../../components/ChatOptions'
import { getUserInfoList } from "../../library/Cookie";
import React from "react";
const Chat = () => {
  const lastQuestionRef = useRef<string>('')
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null)
  //控制是否 loading
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //控制loading message
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false)
  //控制引用消息
  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >()
  //控制侧边栏开启
  const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false)
  //控制答案
  const [answers, setAnswers] = useState<ChatMessage[]>([])
  // 控制异步操作
  const abortFuncs = useRef([] as AbortController[])

  let currentUserCookie = getUserInfoList()

  const getUserHistory = async () => {
    console.log(currentUserCookie)
  }
  //限制聊天次数
  const [sendNumber, setSendNumber] = useState<number>(0)
  //当用户提问时，发送API 请求，并处理接收到的结果
  const makeApiRequest = async (question: string, selectedOption: string,isSelect:boolean) => {
    if (sendNumber >= 5) {
      console.log('限制聊天次数5')
      setIsdisabled(true)
      return
    }
    setIsdisabled(false)
    lastQuestionRef.current = question
    setIsLoading(true)
    setShowLoadingMessage(true)
    const abortController = new AbortController()
    abortFuncs.current.unshift(abortController)

    const userMessage: ChatMessage = {
      role: 'user',
      content: question,
      isSelect: isSelect,
      num: sendNumber,
    }

    const request: ConversationRequest = {
      messages: [
        ...answers.filter((answer) => answer.role !== 'error'),
        userMessage,
      ],
      classify: selectedOption,
    }
    let result = {} as ChatResponse
    try {
      const response = await conversationApi(request, abortController.signal)
      if (response?.body) {
        const reader = response.body.getReader()
        let runningText = ''
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          // 函数使用 TextDecoder 将读取的数据解码为文本，并根据换行符将文本拆分为多个对象。
          //然后，函数尝试将拆分的对象解析为 JSON，并将解析结果存储到 result 中。
          //同时，函数会更新 answers 状态，将用户消息和 API 返回的消息添加到数组中。
          var text = new TextDecoder('utf-8').decode(value)
          const objects = text.split('\n')
          objects.forEach((obj) => {
            try {
              runningText += obj.replace('data:', '')
              if (runningText !== '') {
                result = JSON.parse(runningText)
                setShowLoadingMessage(false)
                var message = result.choices[0].messages.map((message) => {
                  return {
                    ...message,
                    isSelect: isSelect,
                    num:sendNumber
                  }
                });
                setAnswers([
                  ...answers,
                  userMessage,
                  ...message,
                ])
                runningText = ''
              }
            } catch {}
          })
        }
        var message = result.choices[0].messages.map((message) => {
          return {
            ...message,
            isSelect: isSelect,
            num: sendNumber,
          }
        })
        setAnswers([...answers, userMessage, ...message])
      }
      if (!isSelect) {
        setSendNumber(sendNumber + 1)
      } 
    } catch (e) {
      console.log(e)
      if (!abortController.signal.aborted) {
        let errorMessage = '出错了，请联系管理员.'
        if (result.error?.message) {
          errorMessage = result.error.message
        } else if (typeof result.error === 'string') {
          errorMessage = result.error
        }
        setAnswers([
          ...answers,
          userMessage,
          {
            role: 'error',
            content: errorMessage,
            isSelect: isSelect,
            num: sendNumber,
          },
        ])
      } else {
        setAnswers([...answers, userMessage])
      }
    } finally {
      setIsLoading(false)
      setShowLoadingMessage(false)
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      )
    }
    return abortController.abort()
  }
  //停止生成
  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort())
    setShowLoadingMessage(false)
    setIsLoading(false)
  }
  //在 showLoadingMessage 状态发生变化时，将聊天消息滚动到可视区域
  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' }),
    [showLoadingMessage]
  )

  //显示引用消息侧边栏，可以当作 引导客户委托的界面
  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation.content,
      citation.id,
      citation.title ?? '',
      citation.filepath ?? '',
      '',
      '',
    ])
    setIsCitationPanelOpen(true)
  }

  //选项框点击事件

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message.role === 'tool') {
      try {
        //如果是引导类型的消息，需要怎么处理
        return []
      } catch {
        return []
      }
    }
    return []
  }

  useEffect(() => {
    getUserHistory()
  }, [])
  // 定义状态变量来接收selectedOption的值
  const [selectedOption, setSelectedOption] = useState('EngineeringIssues')
  //控制输入框是否禁用
  const [isdisabled, setIsdisabled] = useState<boolean>(false)
  //点击选项
  const handleOptionClick = (option: any) => {
    makeApiRequest(option.text, option.key,true)
    setSelectedOption(option.key)
    if (option.key == 'DelegateHelp') {
      setIsdisabled(true)
    }
  }
  //点击子选项
  const handleSuboptionClick = (option: any) => {
    makeApiRequest(option.value, option.key, true)
  }
  return (
    <div
      className={styles.container}
      role="main"
      style={{
        backgroundImage: `url(` + bk + `)`,
      }}>
      <Stack horizontal className={styles.chatRoot}>
        <div className={styles.chatContainer}>
          {!lastQuestionRef.current ? (
            <Stack className={styles.chatEmptyState}>
              <h1 className={styles.chatEmptyStateTitle}>开始聊天</h1>
              <h2 className={styles.chatEmptyStateSubtitle}>
                CBS 检测大模型可以回答您关于建筑检测的相关问题
              </h2>
              <ChatOptions onOptionClick={handleOptionClick} />
            </Stack>
          ) : (
            <div
              className={styles.chatMessageStream}
              style={{ marginBottom: isLoading ? '40px' : '0px' }}
              role="log">
              {answers.map((answer, index) => (
                <React.Fragment key={index}>
                  {answer.role === 'user' ? (
                    <div className={styles.chatMessageUser} tabIndex={0}>
                      <div className={styles.chatMessageUserMessage}>
                        {answer.content}
                      </div>
                    </div>
                  ) : answer.role === 'assistant' ? (
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        answer={{
                          answer: answer.content,
                          isSelect: answer.isSelect,
                          num:answer.num,
                          citations: parseCitationFromMessage(
                            answers[index - 1]
                          ),
                        }}
                        isLoading={false}
                        index={index}
                        dataClass={selectedOption}
                        onOptionClick={handleSuboptionClick}
                        onCitationClicked={(c) => onShowCitation(c)}
                      />
                    </div>
                  ) : answer.role === 'error' ? (
                    <div className={styles.chatMessageError}>
                      <Stack
                        horizontal
                        className={styles.chatMessageErrorContent}>
                        <ErrorCircleRegular
                          className={styles.errorIcon}
                          style={{ color: 'rgba(182, 52, 67, 1)' }}
                        />
                        <span>Error</span>
                      </Stack>
                      <span className={styles.chatMessageErrorContent}>
                        {answer.content}
                      </span>
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
              {showLoadingMessage && (
                <>
                  <div className={styles.chatMessageUser}>
                    <div className={styles.chatMessageUserMessage}>
                      {lastQuestionRef.current}
                    </div>
                  </div>
                  <div className={styles.chatMessageGpt}>
                    <Answer
                      answer={{
                        answer: '正在生成你的答案...',
                        isSelect:true,
                        num:sendNumber,
                        citations: [],
                      }}
                      isLoading={true}
                      index={0}
                      dataClass={selectedOption}
                      onOptionClick={handleSuboptionClick}
                      onCitationClicked={() => null}
                    />
                  </div>
                </>
              )}
              <div ref={chatMessageStreamEnd} />
            </div>
          )}

          <Stack horizontal className={styles.chatInput}>
            {isLoading && (
              <Stack
                horizontal
                className={styles.stopGeneratingContainer}
                role="button"
                aria-label="Stop generating"
                tabIndex={0}
                onClick={stopGenerating}
                onKeyDown={(e) =>
                  e.key === 'Enter' || e.key === ' ' ? stopGenerating() : null
                }>
                <SquareRegular
                  className={styles.stopGeneratingIcon}
                  aria-hidden="true"
                />
                <span className={styles.stopGeneratingText} aria-hidden="true">
                  停止生成
                </span>
              </Stack>
            )}
            {/* <div
                                role="button"
                                tabIndex={0}
                                onClick={clearChat}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? clearChat() : null}
                                aria-label="Clear session"
                                >
                                <BroomRegular
                                    className={styles.clearChatBroom}
                                    style={{ background: isLoading || answers.length === 0 ? "#BDBDBD" : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)", 
                                            cursor: isLoading || answers.length === 0 ? "" : "pointer"}}
                                    aria-hidden="true"
                                />
                            </div> */}
            <QuestionInput
              clearOnSend
              placeholder="请输入你的问题……"
              disabled={isLoading}
              disabledStyle={isdisabled}
              onSend={(question) =>
                makeApiRequest(question, selectedOption, false)
              }
            />
          </Stack>
        </div>
        {/* 一个侧边栏，可以当作是引导类消息的特殊界面，可以显示 markdown 格式化的信息 */}
        {answers.length > 0 && isCitationPanelOpen && activeCitation && (
          <Stack.Item
            className={styles.citationPanel}
            tabIndex={0}
            role="tabpanel"
            aria-label="Citations Panel">
            <Stack
              horizontal
              className={styles.citationPanelHeaderContainer}
              horizontalAlign="space-between"
              verticalAlign="center">
              <span className={styles.citationPanelHeader}>Citations</span>
              <DismissRegular
                className={styles.citationPanelDismiss}
                onClick={() => setIsCitationPanelOpen(false)}
              />
            </Stack>
            <h5 className={styles.citationPanelTitle} tabIndex={0}>
              {activeCitation[2]}
            </h5>
            <div tabIndex={0}>
              <ReactMarkdown
                linkTarget="_blank"
                className={styles.citationPanelContent}
                children={activeCitation[0]}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </Stack.Item>
        )}
      </Stack>
    </div>
  )
};

export default Chat;

import {ConversationRequest} from "./models";
import {getUserInfoList} from "../library/Cookie";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {

    // 本地环境调试
    // return await fetch("http://127.0.0.1:8000/api/chat/", {
    return await fetch("https://cbs.power-platform.cn/api/chat/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            chatquestion: options.messages[options.messages.length - 1].content,
            userid: getUserInfoList(),
            chaintype: "stuff",
            searchk: 10,
            messages: options.messages,
            classify: options.classify
        }),
        signal: abortSignal
    });
}

//get user history
